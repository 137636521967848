import { Box, useToast } from '@chakra-ui/react';
import { ColumnFiltersState, createColumnHelper, Row } from '@tanstack/react-table';
import React from 'react';
import { useImmer } from 'use-immer';

import { useLazyGetViewsQuery, ViewOrderBy } from '@/API/views.api';
import { cellFactory } from '@/components/available-preview-list/aplUtils';
import { DEFAULT_TOAST_DURATION, ToastTypes } from '@/constants/defaults';
import { PersonnelViewMembershipAccessTabs, TableColumnIds } from '@/constants/enums';
import { ACCESS_DRAWER_TOP_POSITION } from '@/constants/ui';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setPersonnelMembershipDrawerDraftAccess } from '@/store/slices/personnelMembershipDrawer.slice';
import { ViewLite } from '@/types/view.types';
import { tableColumnFilterFn } from '@/utils/filterFns';

import AvailablePreviewList from '../available-preview-list/AvailablePreviewList';

interface TableState {
  availableItems: Map<number, ViewLite>;
  availableItemsColumnFilters: ColumnFiltersState;
  isLoading: boolean;
  previewItems: Map<number, ViewLite>;
}

// ToDo: Assess the potential extraction of this component to a more generic reusable component to address
// duplication with PersonnelViewMembership

const PersonnelViewAccess = (): React.JSX.Element => {
  const { personnelData, personnelViewAccessDraft, selectedDepartmentIds, selectedTabIndex, selectedTemplateIds } =
    useAppSelector((state) => state.personnelMembershipDrawer);

  const dispatch = useAppDispatch();

  const toast = useToast();

  const [state, updateTableState] = useImmer<TableState>({
    availableItems: new Map<number, ViewLite>(),
    availableItemsColumnFilters: [],
    isLoading: true,
    previewItems: new Map<number, ViewLite>(),
  });

  const [fetchAvailableViews, { isLoading: availableViewsLoading }] = useLazyGetViewsQuery();

  const [fetchCurrentAccessViews, { isLoading: currentAccessViewsLoading }] = useLazyGetViewsQuery();

  React.useEffect(() => {
    if (!personnelViewAccessDraft.viewIds.length) {
      updateTableState((draft) => {
        draft.previewItems = new Map();
      });

      return;
    }

    (async () => {
      try {
        const response = await fetchCurrentAccessViews(
          {
            viewIdsList: personnelViewAccessDraft.viewIds,
          },
          true,
        );

        if (response?.data) {
          updateTableState((draft) => {
            const views = new Map();

            response?.data?.forEach((view) => views.set(view.id, view));

            draft.previewItems = views;
            draft.isLoading = false;
          });
        }
      } catch (err) {
        toast({
          description: 'Unable to fetch currently accessible views',
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          status: ToastTypes.ERROR,
          title: 'Error',
        });
        updateTableState((draft) => {
          draft.isLoading = false;
        });
      }
    })();
    // Initial load only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnelViewAccessDraft.viewIds]);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await fetchAvailableViews(
          {
            departmentList: selectedDepartmentIds,
            filter: '',
            isAscending: true,
            orderBy: ViewOrderBy.ViewName,
            templateList: [],
          },
          true,
        );

        if (response?.data) {
          updateTableState((draft) => {
            const views = new Map();

            response?.data?.forEach((view) => views.set(view.id, view));

            draft.availableItems = views;
          });
        }
      } catch (err) {
        toast({
          description: 'Unable to fetch available views',
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          status: ToastTypes.ERROR,
          title: 'Error',
        });
      }
    })();
  }, [fetchAvailableViews, selectedDepartmentIds, toast, updateTableState]);

  // ToDo: Investigate possibly deduplicating this column definition logic
  const rowTemplateIdFilterFn = React.useCallback(
    (row: Row<ViewLite>, _: string, filterValue: number[]) => {
      if (!filterValue.length) return true;

      // eslint-disable-next-line react/prop-types
      const rowTemplateIds: number[] = row.getValue(TableColumnIds.templateIds);

      return selectedTemplateIds.some((templateId) => rowTemplateIds.includes(templateId));
    },
    [selectedTemplateIds],
  );

  const rowDepartmentIdFilterFn = React.useCallback(
    (row: Row<ViewLite>, _: string, filterValue: number[]) => {
      if (!filterValue.length) return true;

      // eslint-disable-next-line react/prop-types
      const rowDepartmentIds: number[] = row.getValue(TableColumnIds.departmentIds);

      return selectedDepartmentIds.some((departmentId) => rowDepartmentIds.includes(departmentId));
    },
    [selectedDepartmentIds],
  );

  const rowViewIdFilterFn = React.useCallback(
    (row: Row<ViewLite>, _: string, filterValue: number[]) => {
      if (!filterValue.length) return true;

      return personnelViewAccessDraft.viewIds.every((viewId) => viewId !== row.original.id);
    },
    [personnelViewAccessDraft.viewIds],
  );

  const availableColumnHelper = createColumnHelper<ViewLite>();
  const availableItemsColumns = [
    // eslint-disable-next-line react/prop-types
    availableColumnHelper.accessor((row) => row.name, {
      cell: (item) => cellFactory<ViewLite>({ cellContext: item, text: item.row.original.name }),
      enableSorting: true,
      filterFn: (row, columnId, filterValue) => tableColumnFilterFn(row, columnId, filterValue),
      header: () => <Box>Name</Box>,
      id: TableColumnIds.viewName,
      sortDescFirst: true,
    }),
    // eslint-disable-next-line react/prop-types
    availableColumnHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      filterFn: rowViewIdFilterFn,
      id: TableColumnIds.viewId,
    }),
    // eslint-disable-next-line react/prop-types
    availableColumnHelper.accessor((row) => row.templates, {
      cell: undefined,
      enableHiding: true,
      filterFn: rowTemplateIdFilterFn,
      header: undefined,
      id: TableColumnIds.templateIds,
    }),
    // eslint-disable-next-line react/prop-types
    availableColumnHelper.accessor((row) => row.departments, {
      cell: undefined,
      enableHiding: true,
      filterFn: rowDepartmentIdFilterFn,
      header: undefined,
      id: TableColumnIds.departmentIds,
    }),
  ];

  const previewColumnsHelper = createColumnHelper<ViewLite>();

  const previewColumns = [
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.name, {
      cell: (item) => cellFactory<ViewLite>({ cellContext: item, text: item.row.original.name }),
      enableSorting: true,
      filterFn: (row, columnId, filterValue) => tableColumnFilterFn(row, columnId, filterValue),
      header: () => <Box>Name</Box>,
      id: TableColumnIds.viewName,
      sortDescFirst: true,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      id: TableColumnIds.viewId,
    }),
  ];

  const handleSelectedItemsChanged = React.useCallback(
    (items: ViewLite[]) => {
      const viewIds = items.map((item) => item.id);

      dispatch(setPersonnelMembershipDrawerDraftAccess(viewIds));
    },
    [dispatch],
  );

  React.useEffect(() => {
    updateTableState((draft) => {
      draft.availableItemsColumnFilters = [
        {
          id: TableColumnIds.departmentIds,
          value: selectedDepartmentIds,
        },
        {
          id: TableColumnIds.templateIds,
          value: selectedTemplateIds,
        },
        {
          id: TableColumnIds.viewId,
          value: personnelViewAccessDraft.viewIds,
        },
      ];
    });
  }, [updateTableState, selectedDepartmentIds, selectedTemplateIds, personnelViewAccessDraft.viewIds]);

  const isLoading = availableViewsLoading || currentAccessViewsLoading;

  return (
    <AvailablePreviewList
      availableColumns={availableItemsColumns}
      availableColumnsVisibility={{ [TableColumnIds.viewId]: false }}
      availableItems={state.availableItems}
      availableItemsColumnFilters={state.availableItemsColumnFilters}
      availableItemsPrimaryColumnId={TableColumnIds.viewName}
      availableItemsTableLabel={'Available Views'}
      availableRowUniqueKey={TableColumnIds.id}
      isOpen={selectedTabIndex === PersonnelViewMembershipAccessTabs.ACCESS}
      isLoading={isLoading}
      onItemsChanged={handleSelectedItemsChanged}
      previewColumns={previewColumns}
      previewColumnsVisibility={{ [TableColumnIds.viewId]: false }}
      previewItems={state.previewItems}
      previewItemsPrimaryColumnId={TableColumnIds.viewName}
      previewItemsTableLabel={`Views accessible by ${personnelData.name.display}`}
      previewRowUniqueKey={TableColumnIds.id}
      primaryAvailableColumnIndex={0}
      primaryPreviewColumnIndex={0}
      tableColumnIds={TableColumnIds}
      topPosition={ACCESS_DRAWER_TOP_POSITION}
      fullWidth={true}
    />
  );
};

export default PersonnelViewAccess;
