/* eslint-disable camelcase */
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_VIEW } from '@/constants/defaults';
import { ViewEditorDrawerMode, ViewEditorDrawerTabIndices } from '@/constants/enums';
import { SORTING_ORDER } from '@/constants/ui';
import { RootState } from '@/store/store';
import { AssignmentType } from '@/types/assignment.types';
import { DepartmentSlim } from '@/types/department.types';
import { PersonnelItemColored, PersonnelType } from '@/types/personnel.types';
import { TemplateSlim } from '@/types/template.types';
import View, { MultiSortColumn, ViewLayoutListColumns } from '@/types/view.types';

interface UIState {
  advancedSortingRulesEnabled: boolean;
  assignmentTypes: AssignmentType[];
  departmentOptions: DepartmentSlim[];
  hideInactiveAssignments: boolean;
  hideInactivePersonnel: boolean;
  isListViewMultiSort: boolean;
  isLoading: boolean;
  isOpen: boolean;
  mode: ViewEditorDrawerMode;
  navIsBlocked: boolean;
  personnelTypes: PersonnelType[];
  selectedDepartments: number[];
  selectedTemplates: number[];
  showAdvancedSortingRules: boolean;
  showConfirmRemoveDepartment: boolean;
  showConfirmSaveChanges: boolean;
  tabIndex: ViewEditorDrawerTabIndices;
  templateOptions: TemplateSlim[];
}

interface ViewEditorState {
  isDirty: boolean;
  uiState: UIState;
  viewDraft: View;
  viewOriginal: View | undefined;
}

const defaultUIState: UIState = {
  advancedSortingRulesEnabled: false,
  assignmentTypes: [],
  departmentOptions: [],
  hideInactiveAssignments: false,
  hideInactivePersonnel: false,
  isListViewMultiSort: false,
  isLoading: false,
  isOpen: false,
  mode: ViewEditorDrawerMode.CREATE,
  navIsBlocked: false,
  personnelTypes: [],
  selectedDepartments: [],
  selectedTemplates: [],
  showAdvancedSortingRules: false,
  showConfirmRemoveDepartment: false,
  showConfirmSaveChanges: false,
  tabIndex: ViewEditorDrawerTabIndices.VIEW_PROPERTIES,
  templateOptions: [],
};

const initialState: ViewEditorState = {
  isDirty: false,
  uiState: defaultUIState,
  viewDraft: DEFAULT_VIEW,
  viewOriginal: DEFAULT_VIEW,
};

export const viewEditorSlice = createSlice({
  initialState,
  name: 'viewEditor',
  reducers: {
    initializeViewEditor: (state, action) => {
      state.uiState.mode = action.payload.mode;
      state.uiState.isLoading = action.payload.isLoading;
    },
    /** Passing an initial view draft indicates an existing view will be edited.
     * This reducer will initialize the view editor draft and original view drafts
     * with the action payload. Any changes made to the draft can be compared against
     * the original, indicating save logic needs to be performed. */
    initializeViewEditorDraft: (state, action) => {
      state.viewDraft = action.payload;
      state.uiState.selectedDepartments = action.payload.filter.on_departments;
      state.uiState.selectedTemplates = action.payload.filter.on_templates;
      state.viewOriginal = action.payload;
    },
    // This reads/stores the value from the feature flag for Advanced Sorting Rules
    setViewEditorAdvancedSortingRulesIsEnabled: (state, action) => {
      state.uiState.advancedSortingRulesEnabled = action.payload;
    },
    setViewEditorAdvancedSortingRulesIsVisible: (state, action) => {
      state.uiState.showAdvancedSortingRules = action.payload;
    },
    setViewEditorDraftAssignmentTypes: (state, action) => {
      state.uiState.assignmentTypes = action.payload;
    },
    /** Sets the assignments for the view editor draft
     * @param {ViewEditorDraftColoredItem[]} action.payload - An array of themeable assignments containing
     * the assignment id, slot color, and text color to be included in the view.
     * */
    setViewEditorDraftAssignments: (state, action) => {
      state.viewDraft.filter.on_assignments = action.payload;
    },
    /** Sets the department ids for the view editor draft
     * @param {number[]} action.payload - An array of department ids to be included in the view.
     * */
    setViewEditorDraftAutoAddAssignments: (state, action) => {
      state.viewDraft.filter.auto_add_assignments = action.payload;
    },
    setViewEditorDraftAutoAddPersonnel: (state, action) => {
      state.viewDraft.filter.auto_add_personnel = action.payload;
    },
    setViewEditorDraftDemandTallies: (state, action) => {
      state.viewDraft.filter.on_demandTallies = action.payload;
    },
    setViewEditorDraftDepartmentIds: (state, action) => {
      state.viewDraft.filter.on_departments = action.payload;
      state.uiState.selectedDepartments = action.payload;
    },
    setViewEditorDraftMultiColumnSort: (state, action) => {
      state.viewDraft.theme.data.multiSortColumns = action.payload;
    },
     /** It's TRUE if Multiple Sort feature flag is ON AND the view has LIST layout */
    setViewEditorDraftMultiSortListLayout: (state, action) => {
      state.uiState.isListViewMultiSort = action.payload;
    },
    /** Sets the name for the view editor draft
     * @param {string} action.payload - The name to be used for the view. Must be unique.
     */
    setViewEditorDraftName: (state, action) => {
      state.viewDraft.name = action.payload;
    },
    setViewEditorDraftNavIsBlocked: (state, action) => {
      state.uiState.navIsBlocked = action.payload;
    },
    /** Sets the personnel for the view editor draft
     * @param {ViewEditorDraftColoredItem[]} action.payload - An array of themeable personnel containing
     * the personnel id, slot color, and text color to be included in the view.
     */
    setViewEditorDraftPersonnel: (state, action) => {
      state.viewDraft.filter.on_personnel = (action.payload as PersonnelItemColored[]).map((personnelItem) => {
        return {
          color: personnelItem.color,
          colorText: personnelItem.colorText,
          id: personnelItem.id,
        };
      });
    },
    setViewEditorDraftPersonnelTypes: (state, action) => {
      state.uiState.personnelTypes = action.payload;
    },
    setViewEditorDraftSortByAssignment: (state, action) => {
      state.viewDraft.filter.sort_assignments_by = action.payload;
    },
    setViewEditorDraftSortByPersonnel: (state, action) => {
      state.viewDraft.filter.sort_personnel_by = action.payload;
    },
    /** Sets the tally ids for the view editor draft
     * @param {number[]} action.payload - An array of tally ids to be included in the view.
     */
    setViewEditorDraftTallies: (state, action) => {
      state.viewDraft.filter.on_tallies = action.payload;
    },
    /** Sets the template ids for the view editor draft
     * @param {number[]} action.payload - An array of template ids to be included in the view.
     */
    setViewEditorDraftTemplateIds: (state, action) => {
      state.viewDraft.filter.on_templates = action.payload;
      state.uiState.selectedTemplates = action.payload;
    },
    setViewEditorDraftThemeData: (state, action) => {
      Object.assign(state.viewDraft.theme.data, action.payload);
    },
    setViewEditorDraftThemeDataLayout: (state, action) => {
      state.viewDraft.theme.data.layout = action.payload;
    },
    setViewEditorDraftThemeDataLayoutDataType: (state, action) => {
      state.viewDraft.theme.data.dataType = action.payload;
    },
    setViewEditorDraftThemeDataLayoutLeftColumnType: (state, action) => {
      state.viewDraft.theme.data.GridSettings_leftColumnType = action.payload;
    },
    setViewEditorDraftThemeDataLayoutListColumns: (state, action) => {
      state.viewDraft.theme.data.listColumns = action.payload;
    },
    setViewEditorDraftThemeDataLayoutRange: (state, action) => {
      state.viewDraft.theme.data.range = action.payload;
    },
    setViewEditorIsLoading: (state, action) => {
      state.uiState.isLoading = action.payload;
    },
    setViewEditorIsOpen: (state, action) => {
      state.uiState.isOpen = action.payload;
    },
    setViewEditorMode: (state, action) => {
      state.uiState.mode = action.payload;
    },
    setViewEditorShowConfirmRemoveDepartment: (state, action) => {
      state.uiState.showConfirmRemoveDepartment = action.payload;
    },
    setViewEditorShowConfirmSaveChanges: (state, action) => {
      state.uiState.showConfirmSaveChanges = action.payload;
    },
    setViewEditorTabIndex: (state, action) => {
      state.uiState.tabIndex = action.payload;
    },
    viewEditorClose: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  initializeViewEditor,
  initializeViewEditorDraft,
  setViewEditorAdvancedSortingRulesIsEnabled,
  setViewEditorAdvancedSortingRulesIsVisible,
  setViewEditorDraftAssignments,
  setViewEditorDraftAssignmentTypes,
  setViewEditorDraftAutoAddAssignments,
  setViewEditorDraftAutoAddPersonnel,
  setViewEditorDraftDemandTallies,
  setViewEditorDraftDepartmentIds,
  setViewEditorDraftMultiColumnSort,
  setViewEditorDraftMultiSortListLayout,
  setViewEditorDraftName,
  setViewEditorDraftNavIsBlocked,
  setViewEditorDraftPersonnel,
  setViewEditorDraftPersonnelTypes,
  setViewEditorDraftSortByAssignment,
  setViewEditorDraftSortByPersonnel,
  setViewEditorDraftTallies,
  setViewEditorDraftTemplateIds,
  setViewEditorDraftThemeData,
  setViewEditorDraftThemeDataLayout,
  setViewEditorDraftThemeDataLayoutDataType,
  setViewEditorDraftThemeDataLayoutLeftColumnType,
  setViewEditorDraftThemeDataLayoutListColumns,
  setViewEditorDraftThemeDataLayoutRange,
  setViewEditorIsLoading,
  setViewEditorIsOpen,
  setViewEditorMode,
  setViewEditorShowConfirmRemoveDepartment,
  setViewEditorShowConfirmSaveChanges,
  setViewEditorTabIndex,
  viewEditorClose,
} = viewEditorSlice.actions;

const multiSortColumnsMap = (state: RootState) => state.viewEditor.viewDraft.theme.data.multiSortColumns;

const isListViewMultiSort = (state: RootState) => state.viewEditor.uiState.isListViewMultiSort;

// This is used to check if either Assignment or Personnel has been set to have Custom Sorting Order in Multiple Sort Rules
export const makeSelectCustomSortSelectedForTab = () =>
  createSelector(
    [multiSortColumnsMap, isListViewMultiSort, (_state: RootState, selectedTab: ViewLayoutListColumns) => selectedTab],
    (multiSortColumnsMap, isListViewMultiSort, selectedTab) => {

      return isListViewMultiSort && multiSortColumnsMap?.find((rule: MultiSortColumn) => rule.columnValue === selectedTab)?.sortDirection === SORTING_ORDER.CUSTOM;
    },
  );

export default viewEditorSlice.reducer;
