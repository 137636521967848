import { Button, HStack, Spinner } from '@chakra-ui/react';
import React from 'react';

import styles from '@/components/drawer-action-buttons/styles.module.scss';
import { NUMERICAL_ONE } from '@/constants/defaults';
import { ViewEditorDrawerTabIndices } from '@/constants/enums';
import { useAppDispatch } from '@/store/hooks';
import { setViewEditorTabIndex } from '@/store/slices/viewEditor.slice';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';

interface StickyFooterProps {
  onCancel: () => void;
  onSave: () => void;
  isLoading?: boolean;
  isSaveDisabled?: boolean;
  navigationIsBlocked?: boolean;
  showNavigation?: boolean;
  tabIndex?: ViewEditorDrawerTabIndices;
}

const DrawerActionButtons = (props: StickyFooterProps): React.JSX.Element => {
  const { isLoading, isSaveDisabled, navigationIsBlocked, onCancel, onSave, showNavigation, tabIndex } = props;

  const dispatch = useAppDispatch();

  const isNextDisabled = tabIndex === ViewEditorDrawerTabIndices.VIEWER_CONFIGURATION || navigationIsBlocked;

  const isBackDisabled = tabIndex === ViewEditorDrawerTabIndices.VIEW_PROPERTIES || navigationIsBlocked;

  return (
    <HStack className={styles.drawerFooter}>
      <HStack className={styles.buttonsStack} spacing={2}>
        <Button
          color="red.500"
          data-testid={ELEMENT_DATA_TEST_IDS.VIEW_ACCESS_DRAWER_CANCEL_BUTTON}
          onClick={() => onCancel()}
          variant="ghost"
        >
          Exit
        </Button>

        {showNavigation && tabIndex !== undefined && (
          <Button
            color="gray.800"
            variant="outline"
            onClick={() => dispatch(setViewEditorTabIndex(tabIndex - NUMERICAL_ONE))}
            isDisabled={isBackDisabled}
          >
            Back
          </Button>
        )}
        {showNavigation && tabIndex !== undefined && (
          <Button
            color="gray.800"
            variant="outline"
            onClick={() => dispatch(setViewEditorTabIndex(tabIndex + NUMERICAL_ONE))}
            isDisabled={isNextDisabled}
          >
            Next
          </Button>
        )}

        <Button
          colorScheme="blue"
          onClick={onSave}
          isLoading={isLoading}
          spinner={<Spinner />}
          isDisabled={isSaveDisabled}
          data-testid={ELEMENT_DATA_TEST_IDS.VIEW_ACCESS_DRAWER_SUBMIT_BUTTON}
        >
          Save
        </Button>
      </HStack>
    </HStack>
  );
};

export default DrawerActionButtons;
