import { Box } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';

import AvailablePreviewList from '@/components/available-preview-list/AvailablePreviewList';
import { DEFAULT_ADD_ALL_TO_VIEW, DEFAULT_COLOR_ITEM } from '@/constants/defaults';
import { ViewEditorTalliesColumns } from '@/constants/enums';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setViewEditorDraftTallies } from '@/store/slices/viewEditor.slice';
import Tally from '@/types/tally.types';
import { tableColumnFilterFn } from '@/utils/filterFns';

interface ViewEditorTalliesProps {
  isTalliesDataLoading: boolean;
  talliesData: Tally[];
}

const ViewEditorTallies = (props: ViewEditorTalliesProps) => {
  const { isTalliesDataLoading, talliesData } = props;

  const { viewDraft: view } = useAppSelector((state) => state.viewEditor);

  const dispatch = useAppDispatch();

  const { filter } = view;

  // A memoized list of all tallies structure ids which are currently contained in the view filter
  const currentTallyIds = React.useMemo(() => {
    return filter.on_tallies.map((tally) => Number.parseInt(tally.id as string));
  }, [filter.on_tallies]);

  const [availableItems, previewItems] = React.useMemo(() => {
    const availableItems = new Map<number, Tally>();
    const previewItems = new Map<number, Tally>();

    if (isTalliesDataLoading || !talliesData) {
      return [availableItems, previewItems];
    }

    // Hydrate available items
    for (const tally of talliesData) {
      const { id } = tally;

      if (currentTallyIds.includes(id)) {
        continue;
      }

      const { color, colorText } = DEFAULT_COLOR_ITEM;
      const coloredTally = { ...tally, color, colorText, id: tally.id };

      availableItems.set(id, coloredTally);
    }

    // Hydrate preview items
    for (const filterTally of filter.on_tallies) {
      const { id } = filterTally;
      const { color, colorText } = DEFAULT_COLOR_ITEM;
      const tally = talliesData.find((data) => data.id === Number.parseInt(id as string));

      if (!tally) continue;

      const coloredAssignment = { ...tally, color, colorText, id: tally.id };

      previewItems.set(Number.parseInt(id as string), coloredAssignment);
    }

    return [availableItems, previewItems];
  }, [currentTallyIds, filter.on_tallies, isTalliesDataLoading, talliesData]);

  const columnHelper = createColumnHelper<Tally>();

  const availableItemsColumns = [
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.tally_name, {
      cell: undefined,
      enableSorting: true,
      filterFn: (row, columnId, filterValue) => tableColumnFilterFn(row, columnId, filterValue),
      header: () => <Box>Name</Box>,
      id: ViewEditorTalliesColumns.displayName,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.tally_id, {
      cell: undefined,
      enableHiding: true,
      id: ViewEditorTalliesColumns.tallyId,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      id: ViewEditorTalliesColumns.id,
    }),
  ];

  const previewColumnsHelper = createColumnHelper<Tally>();

  const previewColumns = [
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.tally_name, {
      cell: undefined,
      enableSorting: true,
      filterFn: (row, columnId, filterValue) => tableColumnFilterFn(row, columnId, filterValue),
      header: () => <Box>Name</Box>,
      id: ViewEditorTalliesColumns.displayName,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.tally_id, {
      cell: undefined,
      enableHiding: true,
      id: ViewEditorTalliesColumns.tallyId,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      id: ViewEditorTalliesColumns.id,
    }),
  ];

  const handleItemsChanged = React.useCallback(
    (updatedItems: Tally[]) => {
      const filterTallyData = updatedItems.map((item) => ({
        ...DEFAULT_COLOR_ITEM,
        id: item.id,
      }));

      dispatch(setViewEditorDraftTallies(filterTallyData));
    },
    [dispatch],
  );

  return (
    <AvailablePreviewList
      availableColumns={availableItemsColumns}
      availableColumnsVisibility={{
        [ViewEditorTalliesColumns.id]: false,
        [ViewEditorTalliesColumns.tallyId]: false,
      }}
      availableItems={availableItems}
      availableItemsPrimaryColumnId={ViewEditorTalliesColumns.displayName}
      availableItemsTableLabel={'Available Tallies'}
      availableRowUniqueKey={ViewEditorTalliesColumns.id}
      isOpen={true}
      isLoading={isTalliesDataLoading}
      onItemsChanged={handleItemsChanged}
      overrideSorting={true}
      previewColumns={previewColumns}
      previewColumnsVisibility={{
        [ViewEditorTalliesColumns.id]: false,
        [ViewEditorTalliesColumns.tallyId]: false,
      }}
      previewItems={previewItems}
      previewItemsPrimaryColumnId={ViewEditorTalliesColumns.displayName}
      previewItemsTableLabel={'Selected Tallies'}
      previewRowsAreDraggable={true}
      previewRowUniqueKey={ViewEditorTalliesColumns.id}
      primaryAvailableColumnIndex={0}
      primaryPreviewColumnIndex={0}
      selectAllTextOverride={DEFAULT_ADD_ALL_TO_VIEW}
      showSideControls={true}
      tableColumnIds={ViewEditorTalliesColumns}
    />
  );
};

export default ViewEditorTallies;
