import { HStack, VStack } from '@chakra-ui/react';
import React from 'react';
import { useImmer } from 'use-immer';

import DepartmentsDropdown from '@/components/departments-dropdown/DepartmentsDropdown';
import PersonnelTypeDropdown from '@/components/personnel-types-dropdown/PersonnelTypeDropdown';
import PublicURLControls from '@/components/view-access-drawer/PublicURLControls';
import { FULL_WIDTH, TOP_BAR_Z_INDEX, ZERO_SPACE } from '@/constants/ui';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  setViewAccessIsDepartmentListClosed,
  setViewAccessPersonnelTypeIds,
  setViewAccessSelectedDepartmentIds,
} from '@/store/slices/viewAccess.slice';
import { PersonnelType } from '@/types/personnel.types';

interface ViewAccessControlsProps {
  actionButtons: React.ReactNode;
  personnelTypes: PersonnelType[];
}

interface UIState {
  isDepartmentListOpen: boolean;
  searchValue: string;
  selectedDropdownDepartmentIds: number[];
}

const ViewAccessControls = (props: ViewAccessControlsProps) => {
  const { actionButtons, personnelTypes } = props;

  const dispatch = useAppDispatch();

  const [uiState, updateUIState] = useImmer<UIState>({
    isDepartmentListOpen: false,
    searchValue: '',
    selectedDropdownDepartmentIds: [],
  });

  const { departments } = useAppSelector((state) => state.departmentsAndTemplates);
  const { selectedPersonnelTypeIds } = useAppSelector((state) => state.viewAccess);

  const personnelTypeOptions = React.useMemo(() => {
    return personnelTypes.map((personnelType) => {
      return {
        label: personnelType.name,
        value: personnelType.ptypeId,
      };
    });
  }, [personnelTypes]);

  const handlePersonnelTypesChange = React.useCallback(
    (selectedPersonnelTypeIds: number[]) => {
      dispatch(setViewAccessPersonnelTypeIds(selectedPersonnelTypeIds));
    },
    [dispatch],
  );

  const handleDepartmentsChange = React.useCallback(
    (selectedIds: number[]) => {
      updateUIState((draft) => {
        draft.selectedDropdownDepartmentIds = selectedIds;
      });
    },
    [updateUIState],
  );

  const handleDepartmentsListClose = React.useCallback(
    (selectedIds: number[]) => {
      dispatch(setViewAccessSelectedDepartmentIds(selectedIds));
      dispatch(setViewAccessIsDepartmentListClosed(true));
    },
    [dispatch],
  );

  return (
    <HStack
      align={'flex-end'}
      bg={'#fff'}
      gap={2}
      justify={'space-between'}
      position={'sticky'}
      top={ZERO_SPACE}
      width={FULL_WIDTH}
      zIndex={TOP_BAR_Z_INDEX}
    >
      <VStack width={FULL_WIDTH}>
        <HStack align={'flex-end'} justify={'space-between'} width={FULL_WIDTH}>
          <HStack>
            <DepartmentsDropdown
              departmentChangeHandler={handleDepartmentsChange}
              departmentList={departments}
              departmentListCloseHandler={handleDepartmentsListClose}
              selectedIds={uiState.selectedDropdownDepartmentIds}
            />
            <PersonnelTypeDropdown
              availablePersonnelTypes={personnelTypeOptions}
              onChangeHandler={handlePersonnelTypesChange}
              selectedPersonnelTypeIds={selectedPersonnelTypeIds}
            />
          </HStack>
          {actionButtons}
        </HStack>
        <PublicURLControls />
      </VStack>
    </HStack>
  );
};

export default ViewAccessControls;
